import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import {
  CONTRACT_ADDRESS_MAINNFT,
  CONTRACT_ADDRESS_TEAMNFT,
  CONTRACT_ADDRESS_MBDIVIDENDS
} from '@config/addresses'

import abi_master from "./../ABIs/abi-master.json"
import abi_distributor from "./../ABIs/abi-distributor.json"
import abi_consumer from "./../ABIs/abi-consumer.json"
import abi_mainnft from "./../ABIs/abi-mainnft.json"
import abi_mbdividends from "./../ABIs/abi-mbdividends.json"
import abi_teamnft from "./../ABIs/abi-teamnft.json"
import rightIcon from "../../../images/icon-right.svg";

import "./Tokens.scss";

const Tokens = ({
  web3, walletAddress, connected
}) => {
  const POLL_CONNECT_READY_TIMEOUT = 1000;

  const [mainTokens, setMainTokens] = useState([]);
  const [secondaryTokens, setSecondaryTokens] = useState([]);
  const [depositArrays, setDepositArrays] = useState([]);

  const [eligibleMainTokens, setEligibleMainTokens] = useState(null);
  const [eligibleCurrentIndexes, setEligibleCurrentIndexes] = useState(null);
  const [eligiblePastIndexes, setEligiblePastIndexes] = useState(null);

  const [myShares, setMyShares] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [availableAmount, setAvailableAmount] = useState(0);

  const [txStatus, setTxStatus] = useState(0);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  })

  useEffect(() => {
    if(connected)
      refreshDividends();
  }, [connected]);
  
  const mainTokenHandler = async (maincontract, i) => {
    return await maincontract.methods.tokenOfOwnerByIndex(walletAddress, i).call();
  }

  const secondaryTokenHandler = async (secondarycontract, i) => {
    return await secondarycontract.methods.tokenOfOwnerByIndex(walletAddress, i).call();
  }

  const dividendsHandler = async (dividends, i) => {
    return await dividends.methods.getArrays(i).call();
  }

  const refreshDividends = async () => {
    const maincontract = new web3.eth.Contract(abi_mainnft, CONTRACT_ADDRESS_MAINNFT);
    const secondarycontract = new web3.eth.Contract(abi_teamnft, CONTRACT_ADDRESS_TEAMNFT);
    const dividends = new web3.eth.Contract(abi_mbdividends, CONTRACT_ADDRESS_MBDIVIDENDS);

    const numberOfTokens = await maincontract.methods.balanceOf(walletAddress).call();
    const mtokData = []
    for (let i = 0; i < numberOfTokens; i++) {
      mtokData.push(mainTokenHandler(maincontract, i))
    }
    const mtok = await Promise.all(mtokData)
    setMainTokens(mtok)

    const stokData = []
    const numberOfTokensSec = await secondarycontract.methods.balanceOf(walletAddress).call();
    for (let i = 0; i < numberOfTokensSec; i++) {
      stokData.push(secondaryTokenHandler(secondarycontract, i));
    }
    const stok = await Promise.all(stokData)
    setSecondaryTokens(stok);

    const maxdeposit = await dividends.methods.getLengthOfArrays().call();
    const depositData = []
    for (let i = 0; i < maxdeposit; i++) {
      depositData.push(dividendsHandler(dividends, i));
    }
    const arrs = await Promise.all(depositData);
    setDepositArrays(arrs);

    const currentIndexes = [];
    const pastIndexes = [];
    const maintoks = [];
    for (let i = 0; i < mtok.length; i++) {
      let eligible = false;

      for (let j = 0; j < arrs.length; j++) {
        if (mtok[i] < 15000) {
          // consumer
          if (parseInt(arrs[j][3]) > mtok[i]) {
            pastIndexes.push(j);
            eligible = true;
            break;
          }
        } else if (mtok[i] < 15300) {
          // distributor
          if (parseInt(arrs[j][4]) > mtok[i]) {
            pastIndexes.push(j);
            eligible = true;
            break;
          }
        } else {
          // master brewer
          if (parseInt(arrs[j][5]) > mtok[i]) {
            pastIndexes.push(j);
            eligible = true;
            break;
          }
        }
      }

      if (!eligible) {
        continue;
      }

      maintoks.push(mtok[i]);

      for (let j = 0; j < arrs.length; j++) {
        if (mtok[i] < 15000) {
          // consumer
          if (parseInt(arrs[j][0]) > mtok[i]) {
            currentIndexes.push(j);
            break;
          }
        } else if (mtok[i] < 15300) {
          // distributor
          if (parseInt(arrs[j][1]) > mtok[i]) {
            currentIndexes.push(j);
            break;
          }
        } else {
          // master brewer
          if (parseInt(arrs[j][2]) > mtok[i]) {
            currentIndexes.push(j);
            break;
          }
        }
      }
    }

    setEligibleMainTokens(maintoks);
    setEligibleCurrentIndexes(currentIndexes);
    setEligiblePastIndexes(pastIndexes);

    const _myShares = await dividends.methods.getNoOfTokens(maintoks, stok).call();
    const _totalShares = await dividends.methods.totalShares().call();
    const _availableAmount = await dividends.methods.getEligibleAmount(maintoks, stok, pastIndexes, currentIndexes).call();

    setMyShares(_myShares);
    setTotalShares(_totalShares);
    setAvailableAmount(_availableAmount);
  }

  const claimDividends = async () => {
    if (web3 == null || connected == false || await web3.eth.getCode(CONTRACT_ADDRESS_MAINNFT) == "0x") {
      return;
    }

    const dividends = new web3.eth.Contract(abi_mbdividends, CONTRACT_ADDRESS_MBDIVIDENDS);

    dividends.methods
      .withdrawArray(eligibleMainTokens, secondaryTokens, eligiblePastIndexes, eligibleCurrentIndexes)
      .send({ from: walletAddress })
      .on('error', (error) => {
        displayNotify("error", `Error ${error.code}: ${error.message}`)
        setTxStatus(2); // error
      })
      .on('transactionHash', (hash) => setTxStatus(1))
      .on('receipt', (receipt) => {
        setTxStatus(3);
        refreshDividends();
      });
  }

  const RowForm = (props) => {
    return (
      <div className="form-group row">
        <div className="form-label col-6">
          {props.label}
        </div>
        <div className="col-6">
          <div className="flex items-center justify-between form-text">
            <span>{props.value}</span>
          </div>
        </div>
      </div>
    );
  }

  const displayNotify = (type, content) => {
    setAlertState({
      open: true,
      message: content,
      severity: type,
    })
  }

  return (
    <div className="py-24 home-panel container">
      <div className="row items-center justify-between">
        <div className="left-panel col-6 px-3">
          <div className="text-4xl uppercase outlineText md:text-5xl">
            DIVIDEND TOKENS
          </div>
          <div className="max-w-3xl mx-auto mt-10 text-lg leading-8 text-white">
            We would be nothing without our supporters and community. To give a little something back, 5% of all MasterBrews revenue goes into a Dividend Pool all holders can claim their share from in perpetuity. Tokens become eligible 30 days after their minting date.
          </div>
          <div className="btn-claim mt-10">
            <div className="right-arrow">
              <img src={rightIcon} />
            </div>
            <div className="btn-text cursor-pointer" onClick={claimDividends}>CLAIM DIVIDENDS</div>
          </div>
          <div className="notify-panel mt-3">
            {txStatus > 0 &&
              <>
                <div className="notify-label">
                  Transaction status
                </div>
                <div className="notify-content">{txStatus == 1 ? "PENDING" : txStatus == 2 ? "ERROR" : "SUCCESSFUL!"}</div>
              </>
            }
          </div>
        </div>
        <div className="right-panel col-6">
          <RowForm label="My Tokens" value={myShares}></RowForm>
          <RowForm label="Active Tokens" value={totalShares}></RowForm>
          <RowForm label="My % Of Active" value={totalShares > 0 ? `${Math.round(myShares / totalShares * 10000) / 100}%` : 0}></RowForm>
          <RowForm label="Claimable USDC" value={availableAmount / 1000000}></RowForm>
          {/* <RowForm label="Last Claim"></RowForm> */}
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={alertState.open}
        autoHideDuration={10000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
          className="alert-md"
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

const stateProps = (state) => ({
  web3: state.web3,
  walletAddress: state.walletAddress,
  connected: state.connected
});

export default connect(stateProps, null)(Tokens);