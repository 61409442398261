import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Tokens from "@components/partials/Tokens/Tokens"

const TokensPage = () => (
  <Layout
    title="Masterbrews | Revenue Sharing"
    contentClassName="homePageContent px-0 max-w-full"
  >
    <Tokens />
  </Layout>
)

export default TokensPage
